const getBlurb = (delta) => {
  const allText = delta.blocks.filter(
    (block) => block.type === 'paragraph',
  ).map(
    (element) => element.data.text.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, ''),
  );

  let text = '';
  for (let i = 0; i < allText.length; i++) {
    text = `${text} ${allText[i]}`;
  }
  return `${text.trim().slice(0, 252)}...`;
};

export default getBlurb;
