export default {
  form: {
    title: 'Title',
    font: 'Font family',
  },
  color: {
    scheme: 'Color',
    primary: 'Primary color',
  },
  save: 'Save changes',
  darkTheme: 'Dark theme',
  preview: 'Preview widget',
};
