import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './FooterStyle';
// import facebook from '../../../assets/facebook.svg';
// import twitter from '../../../assets/twitter.svg';
// import youtube from '../../../assets/youtube.svg';
// import linkedin from '../../../assets/linkedin.svg';

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.footer}>
        <div className={classes.container}>
          <Typography variant="subtitle1" style={{ color: 'white' }}>Copyright &copy; 2022 ReleasePad</Typography>
          <div>
            {/* <img className={classes.icon} src={facebook} alt="Imagen no disponible" height="12px" />
            <img className={classes.icon} src={twitter} alt="Imagen no disponible" height="12px" />
            <img className={classes.icon} src={youtube} alt="Imagen no disponible" height="12px" />
            <img className={classes.icon} src={linkedin} alt="Imagen no disponible" height="12px" /> */}
          </div>
        </div>
      </div>
    </div>

  );
};

export default Footer;
