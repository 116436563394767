import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import convert from 'xml-js';
import EditorJs from 'react-editor-js';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Header from '@editorjs/header';
import Table from '@editorjs/table';
import './Editor.css';
import {
  ItalicInlineTool,
  UnderlineInlineTool,
  StrongInlineTool,
} from 'editorjs-inline-tool';
import isEqual from 'react-fast-compare';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';

const Editor = ({ data, editorInstance }) => {
  const { product } = API;
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);

  const EDITOR_JS_TOOLS = {
    header: Header,
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
    },
    list: List,
    image: {
      class: ImageTool,
      config: {
        uploader: {
          async uploadByFile(file) {
            let url;
            try {
              const { data } = await product.preSigned(state.user.token, state.product.id);
              const formData = Object.keys(data.url_fields).reduce((form, key) => {
                form.append(key, data.url_fields[key]);
                return form;
              }, new FormData());

              formData.append('file', file);

              const result = await axios({
                method: 'POST',
                url: data.url,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
              });

              const imageObject = JSON.parse(convert.xml2json(result.data, { compact: true, spaces: 4 }));
              url = imageObject.PostResponse.Location._text;
            } catch (error) {
              url = 'https://connect.eventtia.com/assets/eventtia-new-index/logo-eventtia-white-10ea5eff0b50722ae8f9c3cae52f8c92f4b63dd991a767dddb1c109a603c12d7.jpg';
            }

            return {
              success: 1,
              file: {
                url,
              },
            };
          },
        },
      },
    },
    table: Table,
    italic: ItalicInlineTool,
    underline: UnderlineInlineTool,
    bold: StrongInlineTool,
  };

  return (
    <EditorJs
      holder="custom"
      tools={EDITOR_JS_TOOLS}
      instanceRef={(instance) => editorInstance.current = instance}
      data={data}
      enableReInitialize
      onCompareBlocks={(newData, oldData) => isEqual(newData, oldData)}
    >
      <div id="custom" />
    </EditorJs>
  );
};

export default Editor;
