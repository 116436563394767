import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  Link,
} from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import validate from 'validate.js';
import Password from '../../components/Password';
import Footer from '../../components/Footer';
import Image from '../../../assets/password-planet.png';
import logo from '../../../assets/new-logo.png';
import useStyles from './ResetPasswordStyle';
import API from '../../../config/api';
import { Context } from '../../../context/AppContextProvieder';
import constraints from './constraints';

// Destructuring
const { unregistered } = API;

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const { lang, token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['recoverPassword', 'common']);
  const [formData, setFormData] = useState({
    newPassword: '',
    confPassword: '',
  });
  const [dataErrors, setDataErrors] = useState({
    errors: {},
    touched: {},
    valid: false,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((oldForm) => ({
      ...oldForm,
      [name]: value,
    }));

    setDataErrors((oldErrors) => ({
      ...oldErrors,
      touched: {
        ...oldErrors.touched,
        [name]: true,
      },
    }));
  };

  useEffect(() => {
    const result = validate(formData, constraints(t), { fullMessages: false });

    setDataErrors((oldErrors) => ({
      ...oldErrors,
      errors: result || {},
      valid: result === undefined,
    }));
  }, [formData]);

  const resetPassword = async (recaptchaToken) => {
    try {
      await unregistered.resetPassword({
        new_password: formData.newPassword,
        new_password_confirmation: formData.confPassword,
        recaptcha_token: recaptchaToken,
      }, token);
      history.push(`/${lang}/login`);
      enqueueSnackbar(
        t('recoverPassword:resetPassword:success'),
        { variant: 'success' },
      );
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const verifyRecaptcha = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
    }
    const recaptchaToken = await executeRecaptcha('ResetPassword');
    resetPassword(recaptchaToken);
  };

  const hasError = (field) => (!!(dataErrors.errors[field] && dataErrors.touched[field]));

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <img className={classes.titleLogo} src={logo} alt="" />
          <Divider style={{ margin: '0 25px' }} orientation="vertical" flexItem />
          <Typography className={classes.titleTypography} variant="h2">
            {t('recoverPassword:recoverTitle')}
          </Typography>
        </div>
        <Paper elevation={3} className={classes.paper}>
          <Grid container>
            <Grid className={classes.gridItem} item sm={12} md={6}>
              <form className={classes.form}>
                <Password
                  name="newPassword"
                  label={t('recoverPassword:resetPassword.newPassword')}
                  value={formData.newPassword}
                  handleChange={handleChange}
                  marginValues="0 0 35px 0"
                  errorMessage={
                    hasError('newPassword') ? dataErrors.errors.newPassword[0] : null
                  }
                />
                <Password
                  name="confPassword"
                  label={t('recoverPassword:resetPassword.confPassword')}
                  value={formData.confPassword}
                  handleChange={handleChange}
                  marginValues="0 0 35px 0"
                  errorMessage={
                    hasError('confPassword') ? dataErrors.errors.confPassword[0] : null
                  }
                />
                <Button
                  type="submit"
                  disableElevation
                  disabled={!dataErrors.valid}
                  style={{ marginTop: '30px', width: '177px' }}
                  variant="contained"
                  color="primary"
                  onClick={verifyRecaptcha}
                >
                  {t('common:actions.save')}
                </Button>
              </form>
              <div className={classes.actionsContainer}>
                <div className={classes.lineContainer}>
                  <Typography className={classes.actionsQuestion}>
                    {t('recoverPassword:actions.question')}
                  </Typography>
                  <Link component={RouterLink} to={`/${lang}/login`}>
                    <Typography style={{ fontWeight: 'bold', marginBottom: 16 }}>
                      {t('recoverPassword:actions.answer')}
                    </Typography>
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid
              className={classes.gridItem}
              style={{ alignItems: 'center' }}
              item
              sm={12}
              md={6}
            >
              <img src={Image} className={classes.image} alt="" />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
