import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from '../CustomInput';
import WidgetCode from '../WidgetCode';
import useStyles from './ProductCardStyle';
import successImg from '../../../assets/Illustration_Success.svg';
import validation from '../../../utils/validation';
import isValid from '../../../utils/isValid';
import constraints from './constraints';
import { Context } from '../../../context/AppContextProvieder';
import addPhoto from '../../../assets/add_photo.png';
import { actions } from '../../../context/Reducer';
import API from '../../../config/api';

const ProductCard = ({
  edit, onClose, auxFunction, productData, setProductData, productToken, buttonTxt, activeStep, steps,
}) => {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['product', 'common']);
  const { product } = API;
  const [stepper, setStepper] = useState(null);
  const [width, setWidth] = useState(0);
  const [file, setFile] = useState();
  const currentProduct = state.product;
  const history = useHistory();
  const { lang } = useParams();
  const [errorsData, setErrorsData] = useState({
    name: null,
    website: null,
    description: null,
    uri: null,
  });
  const container = useRef(null);

  const generateUri = (name) => {
    const uriTrimmed = name.trim();
    const uriLowerCase = uriTrimmed.toLowerCase();
    const uriNoSpace = uriLowerCase.replace(/ /g, '-');
    const uriValidChar = uriNoSpace.replace(/[^a-zA-Z0-9_-]/g, '');

    return uriValidChar;
  };

  const handleChange = (event) => {
    const result = validation(event.target.name, event.target.value, constraints(t));

    setErrorsData({
      ...errorsData,
      [event.target.name]: result,
    });

    if (event.target.name === 'name' || event.target.name === 'uri') {
      const uri = generateUri(event.target.value);
      setProductData({
        ...productData,
        [event.target.name]: event.target.value,
        uri,
      });
    } else {
      setProductData({
        ...productData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleImgChange = async (event) => {
    const formData = new FormData();
    formData.append('logo', event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setFile(preview);
    try {
      await product.updateLogo(formData, currentProduct.id, state.user.token);
      enqueueSnackbar(
        t('logoUpdated'),
        { variant: 'success' },
      );
      const currProduct = {
        ...state.product,
        attributes: {
          ...state.product.attributes,
          logo_url: preview,
        },
      };
      dispatch({ type: actions.SET_PRODUCT, payload: currProduct });
    } catch (error) {
      setFile(state.product.attributes.logo_url);
      enqueueSnackbar(
        t('errorUpdating'),
        { variant: 'success' },
      );
    }
  };

  useEffect(() => {
    if (container.current) {
      setWidth(container.current.offsetWidth);
    }
  }, [container]);

  useEffect(() => {
    if (steps.length) {
      const widthTmp = window.innerWidth;
      setStepper(
        <Stepper
          orientation={widthTmp < 600 ? 'vertical' : 'horizontal'}
          activeStep={activeStep}
          classes={{ root: classes.stepper }}
        >
          {steps.map((label, index) => (
            <Step key={`${label}-${index + 1}`} classes={{ root: classes.step }}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>,
      );
    }
    // eslint-disable-next-line
  }, [steps]);

  const renderLogo = () => {
    if (file) {
      return (
        <label className={classes.imageContainer}>
          <input
            className={classes.file}
            type="file"
            id="file"
            accept="image/png, image/jpeg"
            onChange={handleImgChange}
          />
          <img className={classes.logo} src={file} alt="" />
        </label>
      );
    }
    return (
      <Paper
        className={classes.paperImg}
        elevation={3}
      >
        <label className={classes.imageContainer}>
          <input
            className={classes.file}
            type="file"
            id="file"
            accept="image/png, image/jpeg"
            onChange={handleImgChange}
          />
          <img src={addPhoto} alt="" />
        </label>
      </Paper>
    );
  };

  useEffect(() => {
    if (edit && state.product) setFile(state.product.attributes.logo_url);
    // eslint-disable-next-line
  }, [state]);

  return (
    <div className={classes.paperContainer}>
      <Paper className={classes.paperCard} ref={container}>
        <div className={classes.titleContainer}>
          {edit ? (
            <Typography variant="h2">{t('product:card.editProduct')}</Typography>
          ) : (
            <Typography variant="h2">{t('product:card.newProduct')}</Typography>
          )}
          <CloseIcon color="primary" className={classes.closeIcon} onClick={onClose} />
        </div>
        {stepper}
        {activeStep === 0 && (
          <div className={classes.formContainer}>
            <div>
              <CustomInput
                name="name"
                value={productData.name}
                label={t('product:card.nameField')}
                marginValues="45px 0 0 0"
                handleChange={handleChange}
                errorMessage={errorsData.name}
              />
              <CustomInput
                name="website"
                value={productData.website}
                label={t('product:card.websiteField')}
                marginValues="45px 0 0 0"
                handleChange={handleChange}
                errorMessage={errorsData.website}

              />
              <CustomInput
                name="description"
                value={productData.description}
                label={t('product:card.descriptionField')}
                marginValues="45px 0 0 0"
                handleChange={handleChange}
                multiline
                rows={5}
                errorMessage={errorsData.description}

              />
              {!edit && (
                <>
                  <Typography
                    variant="h5"
                    style={{ marginTop: '25px' }}
                  >
                    {t('product:card.uriAddress')}
                  </Typography>
                  <div className={classes.uri}>
                    <Typography>https://pro.releasepad.io/</Typography>
                    <TextField
                      name="uri"
                      value={productData.uri}
                      onChange={handleChange}
                      variant="outlined"
                      helperText={errorsData.uri}
                    />
                  </div>
                </>

              )}
              {edit && (
                <>
                  <Typography
                    variant="h5"
                    style={{ marginTop: '25px', marginBottom: '10px' }}
                  >
                    {t('product:card.uploadImg')}
                  </Typography>
                  {renderLogo()}
                </>
              )}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                style={{ color: '#4E6678' }}
                onClick={onClose}
              >
                {t('common:actions.cancel')}
              </Button>
              <Button
                disabled={!isValid(productData, constraints, t)}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={activeStep === 0 ? auxFunction : onClose}
              >
                {buttonTxt}
              </Button>
            </div>
          </div>
        )}
        {activeStep === 1 && (
          <div className={classes.widgetContainer}>
            <WidgetCode token={productToken} />
            <div className={classes.imgContainer}>
              <img className={classes.scientistImg} src={successImg} alt="Missing" />
              <Typography className={classes.siteReady}>{t('product:card.siteReady')}</Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => history.push(`/${lang}/products/${productData.uri}/posts`)}
              >
                {t('common:actions.finish')}
              </Button>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default ProductCard;

ProductCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  auxFunction: PropTypes.func.isRequired,
  productData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  setProductData: PropTypes.func.isRequired,
  buttonTxt: PropTypes.string.isRequired,
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};
