import React, { useContext, useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Drawer,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Eye from '@material-ui/icons/Visibility';
import categoryNameToValue from '../../../utils/categoryNameToValue';
import API from '../../../config/api';
import useStyles from './TopPostsStyle';
import { Context } from '../../../context/AppContextProvieder';
import getBlurb from '../../../utils/getBlurb';
import CreatePost from '../CreatePost';
import UserViews from '../UserViews';

const TopPosts = ({ posts, setPosts }) => {
  const [state, dispatch] = useContext(Context);
  const { t } = useTranslation(['common', 'posts', 'dashboard']);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [postData, setPostData] = useState({});
  const [postCategories, setPostCategories] = useState([]);
  const { product, utils } = API;
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [viewsPost, setViewsPost] = useState({
    id: null,
    title: null,
    blurb: null
  });
  const [viewsDrawer, setViewsDrawer] = useState(false);


  const getPost = async (postId) => {
    setOpenBackdrop(true);
    try {
      const { data } = await product.showPost(postId, true, state.user.token);
      setPostData({
        id: data.data.id,
        title: data.data.attributes.title,
        category: categoryNameToValue(data.data.attributes.category_name, postCategories),
        delta: data.data.attributes.delta,
        publish_at: data.data.attributes.publish_at,
        draft: data.data.attributes.draft,
      });
      setOpenBackdrop(false);
      setOpen(true);
    } catch (error) {
      setOpenBackdrop(false);
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const updateTopPosts = (blurb) => {
    const postsTmp = [...posts];
    postsTmp.forEach((post) => {
      if (post.id === postData.id) {
        post.title = postData.title;
        post.body = blurb;
      }
    });
    setPosts([...postsTmp]);
  };

  const editPostFunc = async (delta) => {
    const blurb = getBlurb(delta);
    try {
      await product.updatePost(postData.id, {
        title: postData.title,
        blurb,
        delta,
        draft: postData.draft,
        publish_at: postData.publish_at,
        category_id: postData.category,
        product_id: state.product.id,
      }, state.user.token);
      updateTopPosts(blurb);
      setOpen(false);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await utils.allCategories(state.user.token);
      const categories = [];

      Object.entries(data).forEach(([key, value]) => {
        categories.push({ label: key, value });
      });
      setPostCategories(categories);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const openViewsDrawer = (e, {id, title, body}) => {
    e.stopPropagation();
    setViewsPost({ id, title, blurb: body });
    setViewsDrawer(true);
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div style={{ paddingBottom: '50px' }}>
      <UserViews
        open={viewsDrawer}
        post={viewsPost}
        closeDrawer={() => setViewsDrawer(false)}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress />
      </Backdrop>
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <CreatePost
          cardTitle={t('posts:cardTitles.editTitle')}
          newPost={postData}
          setNewPost={setPostData}
          createPost={editPostFunc}
          categories={postCategories}
          cancelFunction={() => setOpen(false)}
        />
      </Drawer>
      <Typography
        variant="h3"
        className={classes.heading}
      >
        {t('dashboard:topPosts.header')}
      </Typography>
      {posts.map((post) => (
        <Paper
          key={post.id}
          className={classes.card}
          onClick={() => getPost(post.id)}
        >
          <Typography variant="h3">{post.title}</Typography>
          <Typography className={classes.postBody}>{post.body}</Typography>
          <div className={classes.bottomContainer}>
            <div
              className={classes.viewsContainer}
              onClick={(e)=>openViewsDrawer(e, post)}
            >
              <Eye className={classes.icon} />
              <Typography className={classes.iconText}>{post.views}</Typography>
              <Typography className={classes.subtitle} variant="subtitle1">
                {t('posts:seenPost')}
              </Typography>
            </div>
            <Typography className={classes.subtitle} variant="subtitle1">
              {t('common:info.createdBy')}
              {' '}
              {post.author}
              {' '}
              -
              {' '}
              {post.creation}
            </Typography>
          </div>
        </Paper>
      ))}
    </div>

  );
};

export default TopPosts;
