import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button, Typography, Drawer, CircularProgress,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useStyles from './ProductsStyle';
import Bar from '../../components/Bar';
import ProductItem from '../../components/ProductItem';
import ProductCard from '../../components/ProductCard';
import { actions } from '../../../context/Reducer';
import { Context } from '../../../context/AppContextProvieder';
import noProductsImg from '../../../assets/Illustration_Notfound.svg'
import API from '../../../config/api';

// Destructuring
const { product, user } = API;

const Products = () => {
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const { lang } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['product', 'common']);
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const steps = [t('product:steps.info'), t('product:steps.widget')];
  const [activeStep, setActiveStep] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [productToken, setProductToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState({
    name: '',
    website: '',
    description: '',
    uri: '',
  });

  const changePage = (e, newPage) => {
    setPage(newPage);
  };

  const getProducts = async () => {
    setIsLoading(true);
    try {
      const { data } = await product.indexProduct(page, 9, state.user.token);
      if (data.data.length === 0) {
        setOpen(true);
      }
      setProducts(data.data);
      setTotalPages(data.meta.total_pages);
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else if (error.request.status === 402) {
        enqueueSnackbar(
          t('common:snackbarMessages:inactiveSubscription'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
    setIsLoading(false);
  };

  const newPayment = async () => {
    try {
      const { data } = await user.paySubscription(state.user.token);
      const { checkout_session_url } = data;
      window.location.href = checkout_session_url;
    } catch (error) {
      if (error.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  const onClose = () => {
    setActiveStep(0);
    setProductData({
      name: '',
      website: '',
      description: '',
      uri: '',
    });
    setOpen(false);
  };

  const newProduct = async () => {
    try {
      const { data } = await product.createProduct({
        name: productData.name,
        website: productData.website,
        description: productData.description,
        product_uri: productData.uri,
      }, state.user.token);
      if (page === 1) {
        getProducts();
      } else {
        setPage(1);
      }
      setProductToken(data.data.attributes.public_token);
      setActiveStep((currState) => (currState + 1));
    } catch (e) {
      if (e.request.status === 404) {
        enqueueSnackbar(
          t('common:snackbarMessages:communication'),
          { variant: 'error' },
        );
      } else if (e.request.status === 402) {
        enqueueSnackbar(
          t('common:snackbarMessages:inactiveSubscription'),
          { variant: 'error' },
        );
      } else if (e.request.status === 422) {
        const { error } = e.response.data;
        if (error.includes('Product limit reached')) {
          enqueueSnackbar(
            t('common:snackbarMessages.reachedProductsLimit'),
            { variant: 'error' },
          );
        } else {
          enqueueSnackbar(
            error,
            { variant: 'error' },
          );
        }
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'error' },
        );
      }
    }
  };

  const removeWidgets = () => {
    const matches = document.querySelectorAll('script[data-div=my-widget]');
    localStorage.removeItem('my-widget');
    matches.forEach((match) => {
      match.remove();
    });
  };

  const fetchUserInfo = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fetchUser = urlParams.get('payment_succeeded');
    if (fetchUser) {
      const stateCopy = { ...state.user };
      stateCopy['subscription'] = 'active';
      dispatch({ type: actions.SET_USER, payload: stateCopy })
    }
  }

  useEffect(() => {
    removeWidgets();
    fetchUserInfo();
  }, []);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, [page]);

  // eslint-disable-next-line no-shadow
  const showProduct = (product) => {
    history.push(`/${lang}/products/${product.attributes.product_uri}/dashboard`);
  };

  return (
    <div>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
        onClose={onClose}
        anchor="right"
      >
        <ProductCard
          onClose={onClose}
          productToken={productToken}
          auxFunction={newProduct}
          productData={productData}
          setProductData={setProductData}
          activeStep={activeStep}
          buttonTxt={t('product:nextBtn')}
          steps={steps}
        />
      </Drawer>
      <Bar siteSelected />
      <div className={classes.body}>
        <div className={classes.productsContainer}>
          <div className={classes.buttonContainer}>
            <Typography variant="h1">
              {t('product:title')}
            </Typography>
            {state.user.subscription === 'active' ? (
              <Button
                className={classes.createButton}
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                {t('createButton')}
              </Button>
            ) : (
              <Button
                className={classes.createButton}
                disableElevation
                variant="contained"
                color="primary"
                onClick={newPayment}
              >
                {t('common:actions.paySubscription')}
              </Button>
            )}
          </div>
          <div className={classes.productsItemContainer}>
            {isLoading && (
              <div className={classes.loaderContainer}>
                <CircularProgress color="primary" />
              </div>
            )}
            {!isLoading && products.map((card) => (
              <div className={classes.productItem} key={card.id}>
                <ProductItem
                  product={card}
                  name={card.attributes.name}
                  description={card.attributes.description}
                  count={card.attributes.posts_count}
                  showProduct={showProduct}
                />
              </div>
            ))}
          </div>
          {totalPages > 1 && (
            <div className={classes.pagination}>
              <Pagination
                page={page}
                color="primary"
                count={totalPages}
                onChange={changePage}
              />
            </div>
          )}
          {products.length === 0 && (
            <div className={classes.noProductsCont}>
              <img src={noProductsImg} alt="Missing" className={classes.noProductsImg} />
              <Typography className={classes.notFoundText}>
                {t('product:notFound')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
