import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F7F8',
    padding: '15px',
    [theme.breakpoints.down('xs')]: {
      padding: '35px 10px',
    },
  },
  container: {
    maxWidth: '900px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  titleLogo: {
    maxWidth: '150px',
  },
  titleTypography: {
    color: '#000614',
    minWidth: '60px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  paper: {
    padding: '65px 55px',
    marginTop: '35px',
    [theme.breakpoints.down('sm')]: {
      padding: '55px 20px 20px 20px',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 15px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '25px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  actionsQuestion: {
    color: '#5E7B91',
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    maxWidth: '70%',
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
  },
}));

export default useStyles;
