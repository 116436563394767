/* eslint-disable react/jsx-props-no-spreading */
// React
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// React router
import { Route, Redirect, useParams } from 'react-router-dom';

// Services
import { Context } from '../context/AppContextProvieder';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const { lang } = useParams();

  return (
    <Route
      {...rest}
      render={(props) => (
        state.user
          ? <Component {...props} />
          : <Redirect to={`/${lang}/login`} />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.element.isRequired,
};
export default PrivateRoute;
