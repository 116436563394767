/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Typography, CircularProgress,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import PublishedPost from '../../components/PublishedPost';
import API from '../../../config/api';
import useStyles from './PublicPostsStyle';
import NoPostsImg from '../../../assets/Illustration_Notfound.svg';
import RPLogo from '../../../assets/favicon-RP.png';

const mapPosts = (mappingPosts) => {
  const array = mappingPosts.map((post) => ({
    id: post.id,
    title: post.attributes.title,
    body: post.attributes.body,
    creation: post.attributes.publish_at,
    category: post.attributes.category_name,
    color: post.attributes.category_color,
    delta: post.attributes.delta,
  }));

  return array;
};

const loadingPosts = (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <CircularProgress />
  </div>
);

const PublicPosts = () => {
  const classes = useStyles();
  const history = useHistory();
  const { unregistered } = API;
  const { lang, uri } = useParams();
  const [posts, setPosts] = useState([]);
  const totalCount = React.useRef();
  const [hasMore, setHasMore] = useState(true);
  const [widget, setWidget] = useState({
    title: '',
    logo: null,
  });
  const { t } = useTranslation(['common', 'posts']);
  const { enqueueSnackbar } = useSnackbar();

  const fetchPosts = async (page) => {
    if (!hasMore) return;
    try {
      const { data } = await unregistered.findPublishedPosts(uri, page, 10, true);
      const mappedPosts = mapPosts(data.data);
      setWidget({
        title: data.meta.widget.title,
        logo: data.meta.widget.logo || RPLogo,
      });
      setPosts((oldPosts) => [...oldPosts, ...mappedPosts]);
      totalCount.current = data.meta.total_count;
      if (data.meta.last_page || data.meta.total_count === 0) setHasMore(false);
    } catch (error) {
      if (error.request.status === 404) {
        history.push(`/${lang}/login`);
        enqueueSnackbar(
          t('common:snackbarMessages:productNotFound'),
          { variant: 'warning' },
        );
      } else {
        enqueueSnackbar(
          t('common:snackbarMessages:serverError'),
          { variant: 'warning' },
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`Changelog ${widget.title} - Powered by ReleasePad`}</title>
        <meta name="description" content={`${widget.title} product changelog. Contains improvements, new features and bug fixes`} />
      </Helmet>
      {/* <AppBar className={classes.bar} position="static">
        <Toolbar>
          <img
            className={classes.logo}
            onClick={() => history.push(`/${lang}/`)}
            src={logo}
            alt="ReleasePad"
          />
          <span className={classes.separator} />
          <Typography>Release notes</Typography>
        </Toolbar>
      </AppBar> */}
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <img src={widget.logo} className={classes.widgetLogo} />
          <Typography variant="h1">{widget.title}</Typography>
        </div>
        {
          totalCount.current === 0 && (
            <div className={classes.imageContainer}>
              <img src={NoPostsImg} alt="No results" />
              <Typography
                className={classes.notFoundText}
                align="center"
              >
                {t('posts:public.noPosts')}
              </Typography>
            </div>
          )
        }
        <InfiniteScroll
          loadMore={fetchPosts}
          hasMore={hasMore}
          loader={loadingPosts}
          useWindow={false}
        >
          {posts.map((post) => (
            <PublishedPost
              key={post.id}
              title={post.title}
              body={post.body}
              creation={post.creation}
              category={post.category}
              color={post.color}
              delta={post.delta}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default PublicPosts;
